import React, { useState, useContext } from "react";
import { Link as RouterLink } from "react-router-dom";
import { makeStyles } from '@material-ui/core';
import logodash from "../../assets/logo_dark.svg";
import background from "../../assets/bg_login.jpg";

import {
  Paper,
  CssBaseline,
  TextField,
  Grid,
  Box,
  Typography,
  InputAdornment,
  IconButton,
  Link,
  Hidden,
} from "@material-ui/core";

import { Visibility, VisibilityOff } from "@material-ui/icons";

import { i18n } from "../../translate/i18n";

import { AuthContext } from "../../context/Auth/AuthContext";

const Copyright = () => {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      {new Date().getFullYear()}
      {" - "}
      <Link color="inherit" href="https://www.criachat.com.br">
        Criachat Soluções em Atendimento
      </Link>
    </Typography>
  );
};

const useStyles = makeStyles((theme) => ({
    
  loginPageContent: {
    height: '100%',
  },

  screenSection: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    textAlign: 'center',
  },

  logocriachat: {
    marginBottom: '15px',
  },
  
  bgPageside: {
    backgroundImage: `url(${background})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundColor: "rgb(37 211 102)",
    backgroundBlendMode: "multiply",
  },
  
  loginCard: {
    display: 'flex',
    height: '50%',
    width: '90%',
    minHeight: '500px',
    maxWidth: '460px',
    flexDirection: 'column',
    justifyContent: 'space-around',
    padding: theme.spacing(5),
    [theme.breakpoints.only('xs')]: {
      height: '100%',
      width: '100%',
    },
  },

  title: {
    display: 'flex',
  },

  titleText: {
    marginLeft: theme.spacing(1),
  },

  loginForm: {
    display: 'flex',
    flexDirection: 'column',
  },

  passwordInput: {
    marginTop: theme.spacing(1),
  },

  psswdOptions: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
  },

  switchContainer: {
    display: 'flex',
    flexWrap: 'wrap',
  },

  loginOptions: {
    alignSelf: 'center',
    marginTop: theme.spacing(1),
    cursor: 'pointer',
  },
}))

const Login = () => {
  const classes = useStyles();

  const [user, setUser] = useState({ email: "", password: "" });
  const [showPassword, setShowPassword] = useState(false);

  const { handleLogin } = useContext(AuthContext);

  const handleChangeInput = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value });
  };

  const handlSubmit = (e) => {
    e.preventDefault();
    handleLogin(user);
  };

  return (
    <Grid className={classes.loginPageContent} container>
      <Grid item xs md={6}>
        <Box className={classes.screenSection}>
          <Box className={classes.loginCard}>
          <CssBaseline />
          <div className={classes.paper}>
            <img className={classes.logocriachat} src={logodash} alt="logo" />
            <Typography component="h1" variant="h5">
              {i18n.t("login.title")}
            </Typography>
            <form className={classes.form} noValidate onSubmit={handlSubmit}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label={i18n.t("login.form.email")}
                name="email"
                value={user.email}
                onChange={handleChangeInput}
                autoComplete="email"
                autoFocus
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label={i18n.t("login.form.password")}
                id="password"
                value={user.password}
                onChange={handleChangeInput}
                autoComplete="current-password"
                type={showPassword ? "text" : "password"}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setShowPassword((e) => !e)}
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <input type="submit" className="button login" value={i18n.t("login.buttons.submit")} />
            </form>
            <Box mt={8}>{<Copyright />}</Box>
          </div>
          </Box>
        </Box>
      </Grid>
      <Hidden smDown>
        <Grid
          item
          md={6}
          className={classes.bgPageside}
        />
      </Hidden>
    </Grid>
  );
};

export default Login;
